<a class="campaign-item" href="{{ campaignUrl() }}">
  <div class="campaign-item-img">
    <img
      class="campaign-item-img-hero"
      src="{{ heroImageUrl() }}"
      loading="lazy"
    />
    <div class="campaign-item-status">
      <img
        alt="campaign-{{ statusFilter()?.label | translate }}"
        class="status-icon"
        src="/assets/images/icons/{{ statusFilter()?.icon }}.svg"
      />
      <span>{{ statusFilter()?.label | translate }}</span>
    </div>
  </div>
  <div class="campaign-item-body">
    <div>
      <div class="campaign-item-name">{{ campaign().name }}</div>
      <span class="campaign-type">{{
        "shared.campaignType." + campaign().type | translate
      }}</span>
      <div class="meta">
        <div class="campaign-item-period">
          <mat-icon>insert_invitation</mat-icon>

          @if (campaign().startDate && campaign().endDate) {
            <span class="campaign-item-dates"
              >{{ "shared.fromDate" | translate
              }}<span class="date">{{
                campaign().startDate | localizeDate
              }}</span
              >{{ "shared.toDate" | translate
              }}<span class="date">{{
                campaign().endDate | localizeDate
              }}</span></span
            >
          }
        </div>
        <div class="campaign-item-participants">
          <mat-icon>person</mat-icon>
          <span class="campaign-item-participants"
            >{{ campaign().numberOfPartners }}
            {{ "brand.campaignList.participatingPartners" | translate }}</span
          >
        </div>
      </div>
    </div>
    <div class="campaign-item-footer">
      <div class="sn-links">
        @for (network of socialNetworks(); track network) {
          <img
            alt="{{ network.name }}"
            class="logo"
            src="/assets/images/icons{{ network.icon }}"
          />
        }
      </div>
      <div class="actions">
        @if (campaign().isEditable) {
          <button
            mat-menu-item
            title="{{ 'brand.campaignList.moveButton' | translate }}"
            (click)="$event.preventDefault(); move.emit()"
          >
            <span class="actions-icon-move actions-icon--grey"></span>
          </button>
        }
        <button
          mat-menu-item
          title="{{ 'brand.campaignList.duplicateButton' | translate }}"
          (click)="$event.preventDefault(); duplicate.emit()"
        >
          <mat-icon class="actions-icon--grey">content_copy</mat-icon>
        </button>
        @if (campaign().isEditable) {
          <button
            mat-menu-item
            title="{{ 'brand.campaignList.deleteButton' | translate }}"
            (click)="$event.preventDefault(); delete.emit()"
          >
            @if (!isDeleting()) {
              <mat-icon class="actions-icon--delete">delete</mat-icon>
            } @else {
              <mat-spinner [diameter]="25" />
            }
          </button>
        }
      </div>
    </div>
  </div>
</a>
