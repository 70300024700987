import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  Signal,
  computed,
  inject,
  input,
} from "@angular/core";
import {
  BRAND_CAMPAIGN_STATUS_FILTERS,
  BrandCampaign,
  BrandCampaignStatusConfig,
} from "../../../../shared/models/brandCampaign";
import { BrandUrl } from "../../../brand.url";
import { BrandService } from "../../../../shared/services/api/brand.service";
import { Campaign } from "../../../../shared/models/campaign";

interface SocialNetwork {
  icon: string;
  isUsed: boolean;
  name: string;
}

export const socialNetworksSignal = (campaign: Signal<Campaign>) =>
  computed<SocialNetwork[]>(() =>
    [
      {
        name: "Google Ads",
        isUsed: campaign().isUsingGoogleAds,
        icon: "/ic_google.svg",
      },
      {
        name: "Facebook",
        isUsed: campaign().isUsingFacebook,
        icon: "/ic_facebook.svg",
      },
      {
        name: "Instagram",
        isUsed: campaign().isUsingInstagram,
        icon: "/ic_instagram.svg",
      },
      {
        name: "X/Twitter",
        isUsed: campaign().isUsingTwitter,
        icon: "/ic_twitter.svg",
      },
      {
        name: "LinkedIn",
        isUsed: campaign().isUsingLinkedIn,
        icon: "/linkedin/ic_linkedin.svg",
      },
    ].filter((network) => network.isUsed),
  );

@Component({
  selector: "app-brand-campaign-item",
  templateUrl: "./brand-campaign-item.component.html",
  styleUrl: "./brand-campaign-item.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandCampaignItemComponent {
  public readonly campaign = input.required<BrandCampaign>();
  public readonly isDeleting = input(false);

  @Output() public readonly move = new EventEmitter<void>();
  @Output() public readonly duplicate = new EventEmitter<void>();
  @Output() public readonly delete = new EventEmitter<void>();

  private readonly brandService = inject(BrandService);

  protected readonly campaignUrl = computed(() =>
    BrandUrl.Campaign.Home(this.brandService.currentBrandId, this.campaign()),
  );

  protected readonly heroImageUrl = computed(
    () =>
      this.campaign().listImageUrl ?? "/assets/images/draft-generic-image.jpg",
  );

  protected readonly socialNetworks = socialNetworksSignal(this.campaign);

  protected statusFilter = computed<BrandCampaignStatusConfig | undefined>(
    () => BRAND_CAMPAIGN_STATUS_FILTERS[this.campaign().campaignBrandStatus],
  );
}
